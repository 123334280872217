import React, { useEffect, useState } from 'react';
const ProgBar = ({ value, label, type }) => {

const percentage = (value * 100) + '%';

const [progressValue, setProgressValue] = useState(0);

  //controls progress bar animation
  useEffect(() => {
    setTimeout(() => {
      setProgressValue(value);
    }, 300); 
  }, [value]);

  return (
    <div className='progress-div'>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '5px'}}>
            <progress className='prog-bar' value={progressValue}/>
            <div>{percentage} {type}</div>
        </div>
        <div style={{width: '40%', textAlign: 'left'}}>{label}</div>
    </div>
  );
};

export default ProgBar;
