import React from 'react';
import './Menu.css';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { IconButton } from "@mui/material";
//import { useHistory } from "react-router-dom";

const MenuItem = ({ item, onClick }) => {
   //const history = useHistory();
    const isExternal = item.link.startsWith('http');

    const handleClick = (e) => {
        e.preventDefault();
        if (isExternal) {
            window.open(item.link, '_blank', 'noopener noreferrer');
        } else {
            onClick();
        }
    };

    return (
        <li className="menu-item">
            {/* <div className="menu-item-header">
                <a 
                  href={item.link} 
                  onClick={handleClick}
                  target={isExternal ? "_blank" : "_self"}
                  rel={isExternal ? "noopener noreferrer" : ""}
                >
                    {item.title}
                </a>
                {item.subItems && item.subItems.length > 0 && (
                    <IconButton>
                        <KeyboardArrowRightIcon fontSize="small" color='#5C5B49' style={{ marginRight: '15px' }} />
                    </IconButton>
                )}
            </div> */}
            <a 
            href={item.link} 
            onClick={handleClick}
            target={isExternal ? "_blank" : "_self"}
            rel={isExternal ? "noopener noreferrer" : ""}
            className="menu-item-header" // Ensure styling is applied properly
            >
                <span>{item.title}</span>
                {item.subItems && item.subItems.length > 0 && (
                    <IconButton>
                        <KeyboardArrowRightIcon fontSize="small" style={{ marginRight: '15px', color: '#5C5B49' }} />
                    </IconButton>
                )}
            </a>
                </li>
    );
};

export default MenuItem;
