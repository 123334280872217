import React from 'react';
import './SensorDataTable.css';

// Utility function to format headings
const formatHeading = (heading) => {
    // Convert camelCase or PascalCase to space-separated words
    const formatted = heading.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
    return formatted;
};

const SensorDataTable = ({ metadata, device }) => {
    const isArray = Array.isArray(device);
    const readingTypes = [
        'supplyFanCurrent',
        'compressorCurrent',
        'temperature',
        'humidity',
        'waterUsage',
        'gasUsage',
        'co2',
        'energyUsage'
    ];

    const getUnitType = (readingType) => {
        const units = {
            supplyFanCurrent: ' A',         // Amperes
            compressorCurrent: ' A',        // Amperes
            temperature: '°F',             // Degrees Fahrenheit
            humidity: '%',                 // Percentage
            waterUsage: ' gal',             // Gallons
            gasUsage: ' cu ft',             // Cubic feet
            co2: ' ppm',                     // Parts per million
            energyUsage: ' kWh'             // Kilowatt-hours
        };
    
        return units[readingType] || '';  
    };

    const getReadingValue = (metadata, readingType) => {
        return metadata[readingType] !== undefined ? metadata[readingType] : null;
    };

    const renderTableRows = () => {
        // For alerts that display multiple sensor readings
        if (isArray) {
            return metadata.data.map((item, index) => {
                const correspondingDevice = device.find(dev => dev._id === item.deviceId);
                return (
                    <tr key={index}>
                        <td>{correspondingDevice.device}</td>
                        <td>{correspondingDevice.location.data}</td>
                        {Object.keys(item).map((key, idx) => 
                            key !== 'deviceId' ? ( 
                                <>
                                <td key={idx}>{formatHeading(key)}</td>
                                <td style={{ backgroundColor: '#c4d5c56f', fontWeight: 'bold' }}>
                                {typeof item[key] === 'number' ? item[key].toFixed(2) : item[key]}{getUnitType(key)}</td>
                                </>
                            ) : null
                        )}
                    </tr>
                );
            });
        } else {
            // For alerts that display only one sensor's data
                return readingTypes.map((type, index) => {
                    const value = getReadingValue(metadata, type);
                    if (value !== null) {
                        return (
                            <tr key={index}>
                                <td>{device.device}</td>
                                <td>{device.location.data}</td>
                                <>
                                    <td key={`${type}-header`}>{formatHeading(type)}</td>
                                    <td style={{ backgroundColor: '#c4d5c56f', fontWeight: 'bold' }} 
                                    key={`${type}-value`}>{typeof value === 'number' ? value.toFixed(2) : value}
                                    {getUnitType(type)}</td>
                                </>
                            </tr>
                        );
                    }
                    return null;
                });
            
        }
    }
    
    return (
        <>
            <h1 className="data-header">Alert Data</h1>
            <div className='table-container'>
                <table className="sensor-data-table">
                    <thead className="data-header">
                        <th>Name</th>
                        <th>Location</th>
                        <th style={{width: '150px'}}>Reading</th> 
                        <th>Sensor Data</th>   
                    </thead>
                    <tbody>
                        {renderTableRows()}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default SensorDataTable;