import * as React from 'react';
import { LineChart, lineElementClasses } from '@mui/x-charts/LineChart';
import { AnimatedLine } from '@mui/x-charts';
import { useChartId, useDrawingArea, useXScale } from '@mui/x-charts/hooks';
import { CircularProgress } from '@mui/material'; 
import { useEffect, useState } from 'react';

export function BenefitGraph({ data = {} }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data.xLabels) {
      setLoading(false); 
    }
  }, [data]); 

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
        <CircularProgress />
      </div>
    );
  }

  if (!data.xLabels) return null; 

  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;

  const yearColors = {
    [previousYear]: '#858585', // Previous year 
    [currentYear]: '#02B2AF', // Current year 
  };

  // Generate series dynamically based on available keys (excluding xLabels)
  const series = Object.entries(data)
    .filter(([key]) => key !== "xLabels")
    .map(([year, values]) => ({
      type: 'line',
      data: values,
      name: `${year}`,
      id: `${year}`,
      color: yearColors[year] || '#888888',
    }));

    
  return (
    <LineChart
      series={series}
      xAxis={[{ data: data.xLabels || [], scaleType: 'point' }]}
      height={350}
      sx={{
        '& .MuiMarkElement-root': (theme) => {
          return series.map((s) => {
            // Apply fill color only to marks in the current series
            return {
              [`&.MuiMarkElement-series-${s.name}`]: {
                fill: s.name === `${currentYear}` ? '#02B2AF' : 'white',
              },
            };
          });
        },
      }}
    />
  );
}
