import React, { useState, useEffect } from 'react';
import { OpBarChart } from './OperationsBarChart';
import KeyCard from './KeyCard';
import BenefitStatusCard from '../BenefitStatusCard';
import BenefitService from '../../../services/BenefitService';
import { getCurrentBuilding } from '../../../utils/AuthUtilities';

const OperationalPage = ({ type }) => {
    const [benefitStatus, setBenefitStatus] = useState('');
    const buildingId = getCurrentBuilding();
    const [width, setWidth] = useState('0%');
    const [onBenefitScreen, setOnBenefitScreen] = useState(true);
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [titleColor, setColor] = useState('');
    const [value, setValue] = useState('');
    const [goal, setGoal] = useState('');

    // function for testing/product display - currently not connected to back end
    function getBenefitStatus(type) {
        let status;
        let widthValue;
        let headerTitle;
        let subHeaderTitle;
        let color;
        let improveGoal;
        let dataValue = ''; // Initialize dataValue
        let onScreen = true;

        switch(type) {
            case 'Service':
                status = 'Achieving Goals';
                widthValue = '60%';
                headerTitle = 'Yearly Service & Maintenance Hours';
                subHeaderTitle = '';
                color = 'rgb(41, 98, 0)';
                dataValue = '435';
                improveGoal = '20% Decrease';
                onScreen = false;
                break;
            case 'Insurance':
                status = 'Action Needed';
                widthValue = '74%';
                headerTitle = ' Insurance Related Expenses';
                subHeaderTitle = '(other than premium costs)';
                color = '#00000099';
                dataValue = '$45k'; 
                improveGoal = '10% Decrease';
                break;
            case 'Admin':
                status = 'Achieving Goals';
                widthValue = '60%';
                headerTitle = 'Yearly Admin Hours';
                subHeaderTitle = '';
                color = 'rgb(41, 98, 0)';
                dataValue = '300'; 
                improveGoal = '25% Decrease';
                onScreen = false;
                break;
            case 'Tenants':
                status = 'Action Needed';
                widthValue = '67%';
                headerTitle = 'Yearly Tenant Turnover Hours';
                subHeaderTitle = '';
                color = '#00000099';
                dataValue = '300'; 
                improveGoal = '25% Decrease';
                break;
            case 'Emergency':
                status = 'Achieving Goals';
                widthValue = '60%';
                headerTitle = 'Yearly Emergency Service Hours';
                subHeaderTitle = '';
                color = 'rgb(41, 98, 0)';
                dataValue = '150'; 
                improveGoal = '25% Decrease';
                onScreen = false;
                break;
            case 'Contractor':
                status = 'Achieving Goals';
                widthValue = '60%';
                headerTitle = 'Contractor Costs';
                subHeaderTitle = '';
                color = 'rgb(41, 98, 0)';
                dataValue = '150'; 
                improveGoal = '25% Decrease';
                onScreen = false;
                break;
            case 'Capital':
                status = 'Achieving Goals';
                widthValue = '60%';
                headerTitle = 'Capital Expenditures';
                subHeaderTitle = '';
                color = 'rgb(41, 98, 0)';
                dataValue = '150'; 
                improveGoal = '25% Decrease';
                onScreen = false;
                break;
            default:
                status = 'Action Needed';
                widthValue = '67%';
                headerTitle = 'Operational Performance Goals';
                subHeaderTitle = 'Measured during Operational Hours';
                color = '#00000099';
                dataValue = '67%'; // Example value
                improveGoal = '25% Decrease';
        }

        setBenefitStatus(status);
        setWidth(widthValue);
        setTitle(headerTitle);
        setSubTitle(subHeaderTitle);
        setColor(color);
        setValue(dataValue); // Set dataValue
        setGoal(improveGoal);
        setOnBenefitScreen(onScreen);
    }

    useEffect(() => {
        const fetchGoal = async () => {
            try {
                const goalData = await BenefitService.getBenefitGoals(buildingId);
                if (!goalData.data || goalData.data.length === 0) {
                    console.warn("No goals found for this building.");
                    return;
                }
    
                // Map the resource to its corresponding goal type
                const resourceMap = {
                    "Service": "serviceMaintenanceHours",
                    "Insurance": "insuranceCosts",
                    "Admin": "adminHours",
                    "Tenants": "tenantHours",
                    "Emergency": "emergencyServiceHours",
                    "Capital": "capitalExpenditures",
                    "Contractor": "contractorCosts"
                };
    
                // Get the specific goal type based on the selected resource
                const selectedGoalKey = resourceMap[type];
    
                if (!selectedGoalKey) {
                    console.warn(`Invalid resource type: ${type}`);
                    return;
                }
    
                // Extract the goal from the "resource" section
                const selectedGoal = goalData.data[0].operations[selectedGoalKey] || null;
                console.log(`Selected Goal for ${type}:`, selectedGoal);
    
                setGoal(selectedGoal); 
    
            } catch (error) {
                console.error('Failed to fetch goal:', error);
            }
        };
    
        fetchGoal();
    }, [buildingId, type]);

    // useEffect to update benefit status and width based on resource
    useEffect(() => {
        getBenefitStatus(type);
    }, [type]);

    //Dummy data
    const ytdData = [
        { year: 2024, value: 400 },
        { year: 2025, value: 360 },
        { percentageChange: -10 },
    ];

    return (
        <>
            <div className='savings-header'><div className='savings-header'>
                Goal: <span style={{color: titleColor}}>{goal?.goalPct ? `${goal.goalPct}%` : 'N/A'}</span> Reduction in {title}
            </div></div>
            <div className='savings-sub-header'>
                <KeyCard color='white' stroke='#02B2AF' label='Total Projection for 2024'/>
            </div>
            <OpBarChart type={type}/>
            <div className='ytd-header' style={{marginTop: '20px'}}>YTD Consumption</div>
            <div className='savings-data'>
                {ytdData.slice(0, 2).map((item) => ( 
                    <div key={item.year} className='savings'>
                        {item.year}: <span>{item.value.toFixed(2)} </span>
                        Hours
                    </div>
                ))}
                {ytdData[2]?.percentageChange && (
                    <div className='savings'>
                        <span style={{ color: ytdData[2].percentageChange < 0 ? '#296200' : '#F44336' }}>
                            {Math.abs(ytdData[2].percentageChange).toFixed(2)}% {ytdData[2].percentageChange < 0 ? 'Decrease' : 'Increase'}
                        </span>
                    </div>
                )}
            </div>
            <div className='savings-container'>
                <BenefitStatusCard 
                    benefitStatus={benefitStatus} 
                    width={width} 
                    onBenefitScreen={onBenefitScreen}
                    type='Operations'
                />
            </div>
        </>
    );
};

export default OperationalPage;
