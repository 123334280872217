import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: 8,
    minWidth: 180,
    //backgroundColor: '#CCDAC5',
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
  },
  '& .MuiMenuItem-root': {
    '&:hover': {
      //backgroundColor: '#B8C9AE',
    },
  },
}));

const SuiteDropdown = ({ suites, onSelect }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSuite, setSelectedSuite] = useState('Select Suite');
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (suite) => {
    setAnchorEl(null);
    if (suite) {
      setSelectedSuite(suite.name);
      onSelect(suite);
    }
  };

  return (
    <div>
      <Button
        id="suite-dropdown-button"
        aria-controls={open ? 'suite-dropdown-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          backgroundColor: '#CCDAC5',
          color: 'rgb(55, 65, 81)',
          '&:hover': { backgroundColor: '#B8C9AE' },
        }}
      >
        {selectedSuite}
      </Button>
      <StyledMenu
        id="suite-dropdown-menu"
        MenuListProps={{
          'aria-labelledby': 'suite-dropdown-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose(null)}
      >
        {suites.map((suite, index) => (
          <MenuItem key={index} onClick={() => handleClose(suite)}>
            Suite {suite.suite}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};

export default SuiteDropdown;
