import React from 'react';
import { useHistory } from 'react-router-dom';
import '../MainDash/Alerts/AlertInfoPage.css';
import { useParams } from 'react-router-dom';
import '../MainDash/Alerts/AlertPage.css';
import '../MainDash/NewHome.css';
import '../MainDash/Alerts/AssignAlert.css';
import { IconButton } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const ComingSoon = () => {
    const history = useHistory();
    const { title } = useParams();
    console.log(title);

    return (
        <div className='FeedbackPage'>
            <div className='alerts-section'>
                <div className="alerts-header">
                    <div className="title-container">
                        <IconButton onClick={() => history.push('/Home')}>
                            <ArrowBackIosNewIcon
                                className="z-0"
                                style={{ color: 'rgb(14, 81, 46)' }}
                            />
                        </IconButton>
                        <h2 className='input-header'>{title}</h2>
                        <div className="placeholder" style={{ width: '40px', backgroundColor: 'transparent' }}></div>
                    </div>
                </div>
                <div className='feedback-content' style={{ gap: '5px', textAlign: 'center', paddingBottom: '10px' }}>
                    <h1 className='input-header' style={{fontWeight: 'bold'}}>Coming Soon!</h1>
                    <p style={{display: 'flex', textAlign: 'left'}}>We're working hard to bring this feature to you. Stay tuned!</p>
                </div>
            </div>
        </div>
    );
};

export default ComingSoon;