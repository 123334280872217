import React, { useState, useEffect } from 'react';
import ProgBar from './ProgBar';
import { OccupantGraph } from './OccupantGraph';
import BenefitStatusCard from '../BenefitStatusCard';
import BenefitService from '../../../services/BenefitService';
import { getCurrentBuilding } from '../../../utils/AuthUtilities';

const OccupantPage = ({ type }) => {
    const buildingId = getCurrentBuilding();
    const [benefitStatus, setBenefitStatus] = useState('');
    const [width, setWidth] = useState('0%');
    const [onBenefitScreen, setOnBenefitScreen] = useState(true);
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [titleColor, setColor] = useState('');
    const [value, setValue] = useState('');
    const [goal, setGoal] = useState(null);

    // function for testing/product display - currently not connected to back end
    function getBenefitStatus(type) {
        let status;
        let dataValue;
        let widthValue;
        let headerTitle;
        let subHeaderTitle;
        let color;
        let improveGoal;
        let onScreen = true;

        if (type === 'Air Quality') {
            status = 'Achieving Goals';
            widthValue = '60%';
            dataValue = '95%';
            headerTitle = 'Daily Average Air Quality';
            subHeaderTitle = 'Measured during Occupied Hours';
            color = 'rgb(41, 98, 0)';
            improveGoal = '95%+ Daily Average';
            onScreen = false;
        } else if (type === 'Thermal Comfort') {
            status = 'Action Needed';
            dataValue = '74%';
            headerTitle = 'Daily Average Thermal Comfort';
            subHeaderTitle = 'Measured during Occupied Hours';
            color = '#00000099';
            improveGoal = '95%+ Daily Average';
            widthValue = '74%';
        } else if (type === 'Service Time') {
            status = 'Achieving Goals';
            dataValue = '8 Hour';
            widthValue = '60%';
            subHeaderTitle = 'From Start of Service to Resolution';
            color = 'rgb(41, 98, 0)';
            improveGoal = '50% Faster to Resolution';
            onScreen = false;
        } else {
            status = 'Action Needed';
            dataValue = '2';
            headerTitle = 'Quarterly Survey Score';
            subHeaderTitle = '';
            color = '#00000099'
            improveGoal = '20% Increase in Score';
            widthValue = '67%';
        }

        setBenefitStatus(status);
        setWidth(widthValue);
        setTitle(headerTitle);
        setSubTitle(subHeaderTitle);
        setColor(color);
        setOnBenefitScreen(onScreen);
        setValue(dataValue);
        setGoal(improveGoal);
    }

    useEffect(() => {
        const fetchGoal = async () => {
            try {
                const goalData = await BenefitService.getBenefitGoals(buildingId);
                if (!goalData.data || goalData.data.length === 0) {
                    console.warn("No goals found for this building.");
                    return;
                }
                console.log(goalData);
                // Map the resource to its corresponding goal type
                const resourceMap = {
                    "Air Quality": "airQuality",
                    "Thermal Comfort": "thermalComfort",
                    "Survey": "satisfactionSurvey"
                };
    
                if (type === "Service Time") {
                    // Collect all service time goals into an array
                    const serviceGoals = [
                        { label: "Emergency Hours", ...goalData.data[0].satisfaction.emergencyServiceResolutionTime },
                        { label: "Urgent Hours", ...goalData.data[0].satisfaction.urgentServiceResolutionTime },
                        { label: "Non-Urgent Hours", ...goalData.data[0].satisfaction.nonurgentServiceResolutionTime }
                    ].filter(goal => goal.goal !== undefined && goal.goal !== null);
    
                    console.log(`Service Time Goals:`, serviceGoals);
                    setGoal(serviceGoals);
                } else {
                    // Handle non-service time goals
                    const selectedGoalKey = resourceMap[type];
                    if (!selectedGoalKey) {
                        console.warn(`Invalid resource type: ${type}`);
                        return;
                    }
    
                    const selectedGoal = goalData.data[0].satisfaction[selectedGoalKey] || null;
                    console.log(`Selected Goal for ${type}:`, selectedGoal);
                    setGoal(selectedGoal);
                }
    
    
            } catch (error) {
                console.error('Failed to fetch goal:', error);
            }
        };
    
        fetchGoal();
    }, [buildingId, type]);

    // useEffect to update benefit status and width based on resource
    useEffect(() => {
        getBenefitStatus(type);
    }, [type]);

    return (
        <>
        {type === 'Survey' ? (
            <div className='savings-header'>
                Goal: <span style={{color: titleColor}}>{goal?.goal ? `${goal.goal}` : 'N/A'}</span> {title}
            </div>
        ) : type === 'Service Time' && Array.isArray(goal) ? (
            <div className='savings-header'>
                Goals: 
                {goal.length > 0 ? (
                    goal.map((g, i) => (
                        <div key={i}>
                            <span style={{ color: titleColor }}>{g.goal} hour Average </span>
                            {g.label}
                        </div>
                    ))
                ) : (
                    <span style={{color: titleColor}}>N/A</span>
                )}
                {title}
            </div>
        ) : (
            <div className='savings-header'>
                Goal: <span style={{color: titleColor}}>{goal?.goalPct ? `${goal.goalPct}%` : 'N/A'}</span> {title}
            </div>
        )}
            <div className='savings-sub-header'>{subTitle}</div>

            <OccupantGraph type={type}/>
            {(type === 'Air Quality' || type === 'Thermal Comfort') && (
                <ProgBar value='.94' label='Past 7 Day Daily Average' type={type}></ProgBar>
            )}
            <div className='savings-container'>
                <BenefitStatusCard 
                    benefitStatus={benefitStatus} 
                    width={width} 
                    onBenefitScreen={onBenefitScreen}
                    type='Satisfaction'
                />
            </div>
        </>
    );
};

export default OccupantPage;
