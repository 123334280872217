import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import './BenefitTracker.css';

const AntTabs = styled(Tabs)({
  borderBottom: '0px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: 'rgb(14, 81, 46)',
  },
  '& .MuiTabs-scrollButtons': {
    color: 'rgb(14, 81, 46)',
  },
});

const AntTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  padding: '12px 13px',
  color: '#00000099',
  fontFamily: ["Ubuntu"].join(','),
  fontSize: '16px',
  '&:hover': {
    color: 'rgb(14, 81, 46)',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: 'rgb(14, 81, 46)',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#5C623D',
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1.5 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function ResourceTabs({ tabs, onTabChange, selectedTab}) {
  const [value, setValue] = React.useState(selectedTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (onTabChange) {
      onTabChange(newValue);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <AntTabs value={value} onChange={handleChange} aria-label="resource tabs" 
      variant="scrollable" 
      allowScrollButtonsMobile
      scrollButtons="auto">
        {tabs.map((tab, index) => (
          <AntTab key={index} label={tab.label} {...a11yProps(index)} />
        ))}
      </AntTabs>
      {tabs.map((tab, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {tab.content}
        </CustomTabPanel>
      ))}
    </Box>
  );
}

ResourceTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ).isRequired,
};



