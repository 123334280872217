import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import BenefitService from '../../../services/BenefitService';
import { getCurrentBuilding } from '../../../utils/AuthUtilities';

const GoalPage = ({ type }) => {
  //const goals = GoalData[type] || [];
  const [goals, setGoals] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [updatedGoals, setUpdatedGoals] = useState({});
  const buildingId = getCurrentBuilding();
  const [goalId, setGoalId] = useState('');


  const handleChange = (event, key) => {
    setUpdatedGoals((prevGoals) => ({
        ...prevGoals,
        [type]: {
            ...prevGoals[type],
            [key]: {
                ...prevGoals[type]?.[key],
                goalPct: event.target.value,
            },
        },
    }));
};


  const toggleEdit = () => {
    setIsEdit((prev) => !prev);
  };

  const handleSave = async () => {
    try {
      if (goalId) {
        console.log(updatedGoals);        

        const resp = await BenefitService.setBenefitGoals(goalId, updatedGoals);
        console.log(resp);
        console.log('Goal data updated:', goalId);
        setIsEdit(false); 
        alert('Goals Updated Successfully!');
      }
    } catch (error) {
      console.error('Failed to update goal data:', error);
    }
  };


  useEffect(() => {
    const fetchGoals = async () => {
        try {
            const goalData = await BenefitService.getBenefitGoals(buildingId);
            setGoalId(goalData.data[0]._id);
            const filteredGoals = goalData.data.map((goal) => goal[type] || {});
            console.log(filteredGoals);
            setGoals(filteredGoals);
            
        } catch (error) {
            console.error('Failed to fetch goals:', error);
        }
    };

    fetchGoals();
}, [buildingId, type]);


  
return (
  <div style={{ marginTop: '10px' }}>
    {goals.length > 0 && goals.map((goalData, index) => {
      return (
        <div key={index}>
          {Object.keys(goalData).map((key) => (
            <div key={key} style={{ marginBottom: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'flex-start' }}>
                <div style={{ width: '60%' }}>
                  <p style={{ fontWeight: 'bold', textAlign: 'left', color: '#5C623D', margin: 0, fontSize: '17px' }}>
                    {key.replace(/([A-Z])/g, ' $1').toUpperCase()}
                  </p>
                  {key === "satisfactionSurvey" && (
                    <p style={{color: '#00000099', textAlign: 'left', marginLeft: '15px', marginBottom: '0'}}>
                    (Scale of 1-5, 1 is Poor and 5 is Excellent)</p>
                  )}
                  <p style={{ color: '#00000099', textAlign: 'left', marginLeft: '15px' }}>
                  {key === "capitalExpenditures" ? "Budget" : "Last Year"}: {goalData[key].reference}
                  </p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '30%' }}>
                  
                  {key === "satisfactionSurvey" ? (
                    <FormControl sx={{ m: 0.5, minWidth: '80%' }} size="small">
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Goal Score
                      </InputLabel>
                      <NativeSelect
                        defaultValue={4}
                        disabled={!isEdit}
                        onChange={(event) => handleChange(event, key)}
                      >
                      <option value={5}>5</option>
                      <option value={4}>4</option>
                      <option value={3}>3</option>
                      <option value={2}>2</option>
                      <option value={1}>1</option> 
                      </NativeSelect>
                    </FormControl>
                  ) : (
                    <TextField
                    label="Goal"
                    sx={{ m: 1 }}
                    size="small"
                    defaultValue={goalData[key].goalPct}
                    onChange={(event) => handleChange(event, key)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {key === 'capitalExpenditures' ? '$' : '%'}
                        </InputAdornment>
                      ),
                    }}
                    disabled={!isEdit}
                  />
                  )}
                
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    })}

    {/* {type === 'satisfaction' && (
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'flex-start', }}>
        <div style={{width: '60%'}}>
          <p style={{fontWeight: 'bold', textAlign: 'left', color: '#5C623D', margin: 0, fontSize: '17px'}}>
          Average a 4+ on the Quarterly Occupant Satisfaction Survey</p>
          <p style={{color: '#00000099', textAlign: 'left', marginLeft: '15px'}}>
          Scale of 1-5, 1 is Poor and 5 is Excellent</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '30%' }}>
        <FormControl sx={{ m: 0.5, minWidth: 80 }} size="small">
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Goal Score
            </InputLabel>
            <NativeSelect
              defaultValue={4}
              disabled={!isEdit}
            >
            <option value={5}>5</option>
            <option value={4}>4</option>
            <option value={3}>3</option>
            <option value={2}>2</option>
            <option value={1}>1</option> 
          </NativeSelect>
        </FormControl>
        </div>
      </div>
      )} */}

      {isEdit ? (
        <button className="alert-info-button" onClick={handleSave} style={{ padding: '0.35em 1.5em', fontSize: '18px' }}>
          Save
        </button>
      ) : (
        <button className="alert-info-button" onClick={toggleEdit} style={{ padding: '0.35em 1.5em', fontSize: '18px' }}>
        Edit
      </button>
      )}
  </div>
);
};

GoalPage.propTypes = {
type: PropTypes.oneOf(['resource', 'operations', 'satisfaction']).isRequired,
};

export default GoalPage;


