import { getCurrentBuilding } from '../../../utils/AuthUtilities';
import { IconButton } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useHistory } from 'react-router-dom';
import { ResourceTabs } from './ResourceTabs';
import ResourcePage from './ResourcePage';
import OccupantPage from './OccupantPage';
import OperationalPage from './OperationalPage';
import SuiteDropdown from './SuiteDropdown';
import AlertService from '../../../services/AlertService';

import '../Alerts/AlertPage.css';
import '../NewHome.css';
import './BenefitTracker.css';

import React, { useEffect, useState } from 'react';

const BenefitTrackerPage = ({ benefitType, title }) => {
    const buildingId = getCurrentBuilding();
    const history = useHistory();
    const [suites, setSuites] = useState([]);
    const [activeTab, setActiveTab] = useState(0); 
    
    useEffect(() => {
        const fetchBuilding = async () => {
            try {
                const buildingData = await AlertService.getBuildingById(buildingId);
                if (buildingData.suites && buildingData.suites.length > 0) {
                    fetchSuites(buildingData.suites);
                }
            } catch (error) {
                console.error('Failed to fetch builing:', error);
            }
        }
        const fetchSuites = async (suiteIds) => {
            try {
                const suiteDataPromises = suiteIds.map((suiteId) => 
                    AlertService.getSuiteById(suiteId)
                );
                const suiteDataArray = await Promise.all(suiteDataPromises);
                const validSuites = suiteDataArray.filter(suite => suite !== null && suite !== undefined);
                console.log(validSuites);
                setSuites(validSuites);
                
            } catch (error) {
                console.error('Failed to fetch alert:', error);
            }
        };
        fetchBuilding();
 
    }, [buildingId]);


    const handleSuiteSelect = (suite) => {
        console.log("Selected suite:", suite);
    };

    const resourceTabs = [
        { label: 'Electricity', content: <ResourcePage resource='Electricity' /> },
        { label: 'Water', content: <ResourcePage resource='Water' /> },
        { label: 'Gas', content: <ResourcePage resource='Natural Gas' /> },
        { label: 'Oil/Propane', content: <ResourcePage resource='Oil/Propane' /> }
    ];

    const occupantTabs = [
        { label: 'Air Quality', content: <OccupantPage type='Air Quality' /> },
        { label: 'Thermal Comfort', content: <OccupantPage type='Thermal Comfort' /> },
        { label: 'Service Time', content: <OccupantPage type='Service Time' /> },
        { label: 'Survey', content: <OccupantPage type='Survey' /> },
    ];

    const operationalTabs = [
        { label: 'Service & Maintenance', content: <OperationalPage type='Service' /> },
        { label: 'Insurance', content: <OperationalPage type='Insurance' /> },
        { label: 'Contractor Costs', content: <OperationalPage type='Contractor' /> },
        { label: 'Admin', content: <OperationalPage type='Admin' /> },
        { label: 'Tenants', content: <OperationalPage type='Tenants' /> },
        { label: 'Emergency Service', content: <OperationalPage type='Emergency' /> },
        { label: 'Capital', content: <OperationalPage type='Capital' /> },
    ];

    const renderTabs = () => {
        switch (benefitType) {
            case 'Resource Effectiveness':
                return (
                    <>
                        <div className='benefit-header-container'>
                            <h2 className='yearly-header'>Yearly Consumption</h2>
                        </div>
                        <ResourceTabs tabs={resourceTabs} selectedTab={0} padding="8px 16px" />
                    </>
                );
            case 'Occupant Satisfaction':
                return (
                    <>
                        <div className='benefit-header-container'>
                            <h2 className='yearly-header'>Building Management Benchmarks</h2>
                        </div>
                        {/* 0 is for air quality and 1 is for thermal comfort (based on order of tabs) */}
                        {(activeTab === 0 || activeTab === 1) && (
                            <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', marginLeft: '20px', marginBottom: '20px'}}>
                                <SuiteDropdown suites={suites} onSelect={handleSuiteSelect} />
                            </div>
                        )}
                        <ResourceTabs tabs={occupantTabs} onTabChange={setActiveTab} selectedTab={activeTab} padding="8px 16px" />
                    </>
                );
            case 'Operational Efficiency':
                return (
                    <>
                        <div className='benefit-header-container'>
                            <h2 className='yearly-header'>Operational Efficiency Goals</h2>
                        </div>
                        <ResourceTabs tabs={operationalTabs} selectedTab={0} padding="8px 16px" />
                    </>
                );
            default:
                return null;
        }
    };


    return (
        <div className='BenefitPage'>
            <div className='benefit-tracker-section'>
                <div className="alerts-header">
                    <div className="title-container">
                        <IconButton onClick={() => history.goBack()}>
                            <ArrowBackIosNewIcon
                                className="z-0"
                                style={{ color: 'rgb(14, 81, 46)'}}
                            />
                        </IconButton>
                            <h2>{title}</h2>
                            <div className="placeholder" style={{width: '40px', backgroundColor: 'transparent'}}></div>
                        </div>
                </div>
                <div className='benefit-container'>
                        {renderTabs()}
                </div>
            </div>
        </div>

  );

};

export default BenefitTrackerPage;
