import React, { useState } from 'react';
import './AlertInfoPage.css';
import '../NewHome.css';
import './AssignAlert.css';
import AlertModal from './AlertModal';
import AlertService from '../../../services/AlertService';
import { createServiceRecord } from '../../../services/ServiceRecordService';

const ServiceAcknowledged = ({newStatus, alertId, action, buildingId}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleButtonClick = async () => {
        if (newStatus === 'Investigating') {
            const serviceCreated = await startService();
            if (serviceCreated) {
                await updateStatus();
                setIsModalOpen(true);
            }
        } else {
            await updateStatus();
            setIsModalOpen(true);
        }
    };

    const updateStatus = async () => {
        try {
            await AlertService.updateAlertStatus(alertId, newStatus);
        } catch (error) {
            console.error('Error updating alert:', error);
        }
    };

    const startService = async () => {
        try {
            const service = await createServiceRecord(alertId, buildingId);
            console.log(service);
            return true;
        } catch (error) {
            console.error('Error creating record:', error);
            return false;
        }
    };

    const handleClose = () => {
        setIsModalOpen(false);
        window.location.reload();
        
    };

    return (
        <>
        <button className="alert-info-button" onClick={handleButtonClick}>{action} Alert</button>
        <AlertModal 
            isModalOpen={isModalOpen} 
            closeModal={() => setIsModalOpen(false)} 
            confirmModal={handleClose} 
            handleOptionClick={() => {}} 
            selectedOption={null} 
            height="200px"
            title="Success!"
            options={[]}
        >
            <p>Status changed to {newStatus}.</p>
            <button className="modal-button alert-confirm" 
                onClick={handleClose}>Close</button>
        </AlertModal>
        </>
    );
};
export default ServiceAcknowledged 