import React, { useState, useEffect } from 'react';
import { BenefitGraph } from './BenefitGraph';
import KeyCard from './KeyCard';
import BenefitStatusCard from '../BenefitStatusCard';
import BenefitService from '../../../services/BenefitService';
import { getCurrentBuilding } from '../../../utils/AuthUtilities';

const ResourcePage = ({ resource }) => {
    const [benefitStatus, setBenefitStatus] = useState('');
    const [width, setWidth] = useState('0%');
    const [onBenefitScreen, setOnBenefitScreen] = useState(true);
    const [titleUnit, setTitle] = useState('');
    const [titleColor, setColor] = useState('');
    const buildingId = getCurrentBuilding();
    const [graphData, setGraphData] = useState([]);
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;
    const [ytdData, setYtdData] = useState([]);
    const [percentageChange, setPercentageChange] = useState(null);
    const [goal, setGoal] = useState(null);

    async function fetchConsumptionData() {
        try {
            console.log('Function called');
            const buildingId = getCurrentBuilding();
            console.log('Building ID:', buildingId);

            if (!buildingId) {
                console.error('Error: buildingId is undefined!');
                return;
            }

            const months = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            const resourceUnitMap = {
                "Electricity": "kWh",
                "Water": "volumeGal",
                "Natural Gas": "volumeTherms",
                "Oil/Propane": "volumeOil",
            };

            console.log('Resource:', resource);
            const currentUnit = resourceUnitMap[resource];

            if (!currentUnit) {
                console.error('Error: Invalid resource', resource);
                return;
            }

            // Create API requests for both years
            const requests = [];
            for (let year of [previousYear, currentYear]) {
                for (let month = 1; month <= 12; month++) {
                    const monthStr = String(month).padStart(2, '0');
                    console.log(`Fetching data for ${year}-${monthStr}`);
                    requests.push(
                        BenefitService.getMonthlyConsumption(buildingId, year.toString(), monthStr)
                            .catch(error => {
                                console.warn(`No data for ${year}-${monthStr}, setting to 0`);
                                return { data: [] };
                            })
                    );
                }
            }

            const responses = await Promise.all(requests);

            // Separate responses into previous year and current year
            const previousYearData = [];
            const currentYearData = [];

            responses.forEach((response, index) => {
                const year = index < 12 ? previousYear : currentYear;
                const data = response.data.find(item => item.unit === currentUnit);
                const value = data ? data.monthlyConsumptionValue : null;

                if (year === previousYear) {
                    previousYearData.push(value);
                } else {
                    currentYearData.push(value);
                }
            });

            console.log('Final Data:', {
                xLabels: months,
                [previousYear]: previousYearData,
                [currentYear]: currentYearData
            });

            setGraphData({
                xLabels: months,
                [previousYear]: previousYearData,
                [currentYear]: currentYearData
            });

            // Compute Year-To-Date (YTD) Data
            const currentMonthIndex = new Date().getMonth(); // Get current month index (0-based)

            const ytdPreviousYear = previousYearData
                .slice(0, currentMonthIndex + 1) // Sum up until current month
                .reduce((acc, val) => acc + (val || 0), 0);

            const ytdCurrentYear = currentYearData
                .slice(0, currentMonthIndex + 1) // Sum up until current month
                .reduce((acc, val) => acc + (val || 0), 0);

            // Calculate percentage change
            let percentage = null;
            if (ytdPreviousYear > 0) {
                percentage = ((ytdCurrentYear - ytdPreviousYear) / ytdPreviousYear) * 100;
            }

            console.log('YTD Data:', { previousYear: ytdPreviousYear, currentYear: ytdCurrentYear, percentageChange });

            setYtdData([
                { year: previousYear, value: ytdPreviousYear, unit: titleUnit },
                { year: currentYear, value: ytdCurrentYear, unit: titleUnit },
            ]);

            setPercentageChange(percentage);

        } catch (error) {
            console.error('Error fetching monthly consumption:', error);
        }
    }

    useEffect(() => {
        const fetchGoal = async () => {
            try {
                const goalData = await BenefitService.getBenefitGoals(buildingId);
                if (!goalData.data || goalData.data.length === 0) {
                    console.warn("No goals found for this building.");
                    return;
                }
    
                // Map the resource to its corresponding goal type
                const resourceMap = {
                    "Electricity": "electricityConsumption",
                    "Water": "waterConsumption",
                    "Natural Gas": "naturalGasConsumption",
                    "Oil/Propane": "propaneConsumption"
                };
    
                // Get the specific goal type based on the selected resource
                const selectedGoalKey = resourceMap[resource];
    
                if (!selectedGoalKey) {
                    console.warn(`Invalid resource type: ${resource}`);
                    return;
                }
    
                // Extract the goal from the "resource" section
                const selectedGoal = goalData.data[0].resource[selectedGoalKey] || null;
                console.log(`Selected Goal for ${resource}:`, selectedGoal);
    
                setGoal(selectedGoal); 
    
            } catch (error) {
                console.error('Failed to fetch goal:', error);
            }
        };
    
        fetchGoal();
    }, [buildingId, resource]);
    
    useEffect(() => {
        if (goal && ytdData.length > 1) {
            const ytdCurrentYear = ytdData.find(item => item.year === currentYear)?.value || 0;
            const targetReduction = goal.goalPct ? (goal.goalPct / 100) * ytdData[0].value : null;
            const goalThreshold = ytdData[0].value - targetReduction;
    
            if (ytdCurrentYear <= goalThreshold) {
                setBenefitStatus("Achieving Goals");
            } else {
                setBenefitStatus("Action Needed");
            }
        }
    }, [goal, ytdData]);

    useEffect(() => {
        const resourceUnitMap = {
            "Electricity": "kWh",
            "Water": "Gallons",
            "Natural Gas": "Therms",
            "Oil/Propane": "Gallons",
        };
    
        if (resourceUnitMap[resource]) {
            setTitle(resourceUnitMap[resource]); 
        } else {
            setTitle('');
        }
        if (buildingId) fetchConsumptionData();
    }, [resource, buildingId]);

    return (
        <>
            <div className='savings-header'>Goal: 
                <span> {goal?.goalPct ? `${goal.goalPct}%` : 'N/A'} </span> 
                Reduction over Prior Year
            </div>
            <div className='key'>
                <div className='key-title'>{titleUnit} Consumed</div>
                <div className='key-container'>
                    <KeyCard color='#858585' label={previousYear.toString()}/>
                    <KeyCard color='#02B2AF' label={currentYear.toString()} />
                </div>
            </div>
            <BenefitGraph data={graphData}/> 
            <div className='ytd-header'>YTD Consumption</div>
            <div className='savings-data'>
                {ytdData.slice(0, 2).map((item) => ( 
                    <div key={item.year} className='savings'>
                        {item.year}: <span>{item.value.toFixed(2)} </span>
                        {titleUnit}
                    </div>
                ))}
                {ytdData[2]?.percentageChange && (
                    <div className='savings'>
                        <span style={{ color: ytdData[2].percentageChange < 0 ? '#296200' : '#F44336' }}>
                            {Math.abs(ytdData[2].percentageChange).toFixed(2)}% {ytdData[2].percentageChange < 0 ? 'Decrease' : 'Increase'}
                        </span>
                    </div>
                )}
            </div>
            <div className='savings-container'>
                <BenefitStatusCard 
                    benefitStatus={benefitStatus}  
                    onBenefitScreen={onBenefitScreen}
                    type='Resource'
                />
            </div>
        </>
    );
};

export default ResourcePage;
