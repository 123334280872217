import React from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import './NewHome.css';
import { colors } from "../../constants/constants";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";



const BenefitStatusCard = ({benefitStatus, onBenefitScreen, type}) => {
    const history = useHistory();

    let textColor ='white';
    let backgroundColor = 'white';
    let borderColor = 'white';
    let iconColor = 'white';
    
    switch (benefitStatus) {
        case 'Achieving Goals':
            textColor = colors.green;
            backgroundColor = 'white';
            borderColor ='white';
            iconColor = colors.green;
            break;
        case 'Action Needed':
            textColor = colors.alertBackground;       
            borderColor = colors.alertBackground;
            // backgroundColor = '#5C623D';
            iconColor = colors.alertBackground;
            break;
        default:
            // textColor = colors.green;
            borderColor = 'white';
            backgroundColor = 'white';
            break;
    }

    return (
        <div className="status-box"
            onClick={() => history.push(`/BenefitGoals?type=${type}`)}
            style={{
                backgroundColor: backgroundColor,
                border: '1.5px solid' + borderColor,
                cursor: 'pointer',
            }}>
            
                {benefitStatus === 'Achieving Goals' ? (
                    <CheckCircleIcon className='benefit-circle'
                        style={{color: iconColor}}
                    />
                ) : (
                    <ErrorIcon className='benefit-circle'
                        style={{color: iconColor}}
                    />
                )}

                <p className='status-p' 
                    style={{margin: 0, color: textColor, textAlign: 'left', lineHeight: '22px'}}>
                    {benefitStatus}
                </p>

                {onBenefitScreen && benefitStatus === 'Action Needed' && (
                    <IconButton onClick={() => history.push(`/BenefitGoals?type=${type}`)}>
                        <ArrowForwardIos
                            className="z-0"
                            style={{ color: colors.alertBackground }}
                        />
                    </IconButton>
                )}
        </div>
    );
}
export default BenefitStatusCard;

