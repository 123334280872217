import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './NewHome.css'; 

// Import your images and icons
import Messages from '../../images/Messages.png';
import Notification from '../../images/Notifications.png';
import Safety from '../../images/safety.png';
import Resource from '../../images/Resource.png';
import Service from '../../images/Service.png';
import PlaceIcon from '@mui/icons-material/Place';
import BenefitStatusCard from './BenefitStatusCard';
import { getCurrentBuilding } from '../../utils/AuthUtilities';

// import { checkCookieExpiration } from '../AuthUtilities';
import { fetchAlertCounts } from './Alerts/AlertTypePage';
import AlertService from '../../services/AlertService';
// Example data structure

const NewHome = () => {
  const buildingId = getCurrentBuilding();
  const history = useHistory();
  const [healthSafetyCounts, setHealthSafetyCounts] = useState({ newCount: 0, inProcessCount: 0 });
  const [resourceEfficiencyCounts, setResourceEfficiencyCounts] = useState({ newCount: 0, inProcessCount: 0 });
  const [serviceCheckCounts, setServiceCheckCounts] = useState({ newCount: 0, inProcessCount: 0 });
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState('');

    useEffect(() => {
        // Function to fetch and update counts
        const getAlertCounts = async () => {
            try {
                const healthSafetyAlerts = await fetchAlertCounts(["Health & Safety", "Thermal Comfort"], buildingId);
                const resourceEfficiencyAlerts = await fetchAlertCounts(["Resource Efficiency"], buildingId);
                const serviceCheckAlerts = await fetchAlertCounts(["Service Check"], buildingId);

                setHealthSafetyCounts({
                    newCount: healthSafetyAlerts.filter(alert => alert.status === 'Active' || alert.status === 'New').length,
                    inProcessCount: healthSafetyAlerts.filter(alert => alert.status === 'In Progress').length
                });

                setResourceEfficiencyCounts({
                    newCount: resourceEfficiencyAlerts.filter(alert => alert.status === 'Active' || alert.status === 'New').length,
                    inProcessCount: resourceEfficiencyAlerts.filter(alert => alert.status === 'In Progress').length
                });

                setServiceCheckCounts({
                    newCount: serviceCheckAlerts.filter(alert => alert.status === 'Active' || alert.status === 'New').length,
                    inProcessCount: serviceCheckAlerts.filter(alert => alert.status === 'In Progress').length
                });
                
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch alert counts', error);
                setLoading(false);
            }
        };

        getAlertCounts();
    }, [buildingId]);

    useEffect(() => {
      console.log('here: ' + buildingId);
      const getAddress = async () => {
        try {
          const bldg = await AlertService.getBuildingById(buildingId);
          console.log(bldg);
          setAddress(bldg.address);
        } catch (error) {
          console.error('Failed to get address', error);
        }
      }
      getAddress();
    }, [buildingId]);

const alertsData = [
  {
    title: 'Health & Safety',
    icon: Safety,
    newCount: healthSafetyCounts.newCount,
    inProcessCount: healthSafetyCounts.inProcessCount,
    route: '/HealthSafety'
  },
  {
    title: 'Resource Efficiency',
    icon: Resource,
    newCount: resourceEfficiencyCounts.newCount,
    inProcessCount: resourceEfficiencyCounts.inProcessCount,
    route: '/ResourceEfficiency'
  },
  {
    title: 'Service & Maintenance',
    icon: Service,
    newCount: serviceCheckCounts.newCount,
    inProcessCount: serviceCheckCounts.inProcessCount,
    route: '/ServiceCheck'
  }
];
const messageCount = 5; // Example count, should be dynamic based on real data
const externalCount = 2;
  return (
  <div>
  <div className='NewHome'>
    <div className="score-header">
      <div className='building-address'>
        <PlaceIcon></PlaceIcon>
        {address ? address : 'Error loading building'}
      </div>
      <div>Rating: 7.5</div> 
    </div>
    <div className="alerts-section">
      <div className="alerts-header">
        <h2>Alerts</h2>
      </div>
      
      {loading ? (
        <div className='loader-container'>
          <div className="loader"></div>
          </div>
        ) : (
        <div className="alerts-container">
          {alertsData.map(alert => (
            <div className="alert-card" key={alert.title} onClick={() => history.push(alert.route)}>
              <div className="alert-icon-holder">
                <img src={alert.icon} alt={alert.title} className="alert-icon" />
                {alert.newCount > 0 && (
                  <span className="alert-badge">{alert.newCount}</span>
                )}
              </div>
              <h3 className="alert-info">{alert.title}</h3>
            </div>
          ))}
          </div>
    )}
    </div>
    <div className="benefit-tracker-section">
      <div className="benefit-tracker-header">
        <h2>Benefits</h2>
      </div>
      <div className="benefit-tracker-container">
        <div className="benefit-tracker-card" onClick={() => history.push('/OccupantSatisfaction')}>
          <BenefitStatusCard benefitStatus='Achieving Goals'/>
          <hr className='home-hr' style={{margin: '1em 3em 0.5em 3em'}}></hr>
          <h3>Occupant Satisfaction</h3>
        </div>
        <div className="benefit-tracker-card" onClick={() => history.push('/ResourceEffectiveness')}>
          <BenefitStatusCard benefitStatus='Achieving Goals'/>
          <hr className='home-hr' style={{margin: '1em 3em 0.5em 3em'}}></hr>
          <h3>Resource Effectiveness</h3>
        </div>
        <div className="benefit-tracker-card" onClick={() => history.push('/OperationalEfficiency')}>
          <BenefitStatusCard benefitStatus='Achieving Goals'/>
          <hr className='home-hr' style={{margin: '1em 3em 0.5em 3em'}}></hr>
          <h3>Operational Efficiency</h3>
        </div>
      </div>
    </div>
    <div className="messages-notifications-section">
      <div className="messages-notifications-header">
        <h2>Notices</h2>
      </div>
      <div className="messages-notifications-container">
        <div className="notification-card">
          <div className="notif-icon">
            <img src={Messages} alt="Message Board" />
            <span className="notification-badge">{messageCount}</span>
            </div>
          <p>Message Board</p>
        </div>
        <div className="notification-card">
          <div className="notif-icon">
            <img src={Notification} alt="External Notification"/>
            <span className="notification-badge">{externalCount}</span>
          </div>
          <p>External Notification</p>
        </div>
      </div>
    </div>
  </div>
  </div>
  );
};
export default NewHome;