import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { AnimatedLine } from '@mui/x-charts';
import { useChartId, useDrawingArea, useXScale } from '@mui/x-charts/hooks';

function CustomAnimatedLine(props) {
  const { limit, sxBefore, sxAfter, ...other } = props;
  const { top, bottom, height, left, width } = useDrawingArea();
  const scale = useXScale();
  const chartId = useChartId();

  if (limit === undefined) {
    return <AnimatedLine {...other} />;
  }

  const limitPosition = scale(limit); // Convert value to x coordinate.

  if (limitPosition === undefined) {
    return <AnimatedLine {...other} />;
  }

  const clipIdleft = `${chartId}-${props.ownerState.id}-line-limit-${limit}-1`;
  const clipIdRight = `${chartId}-${props.ownerState.id}-line-limit-${limit}-2`;
  return (
    <React.Fragment>
      {/* Clip to show the line before the limit */}
      <clipPath id={clipIdleft}>
        <rect
          x={left}
          y={0}
          width={limitPosition - left}
          height={top + height + bottom}
        />
      </clipPath>
      {/* Clip to show the line after the limit */}
      <clipPath id={clipIdRight}>
        <rect
          x={limitPosition}
          y={0}
          width={left + width - limitPosition}
          height={top + height + bottom}
        />
      </clipPath>
      <g clipPath={`url(#${clipIdleft})`}>
        <AnimatedLine {...other} sx={sxBefore} />
      </g>
      <g clipPath={`url(#${clipIdRight})`}>
        <AnimatedLine {...other} sx={sxAfter} />
      </g>
    </React.Fragment>
  );
}

// All hard coded data for testing
const xLabels_Air = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];
const newData_Air = [99, 92, 96, 96, 96, 95, 94, 95, 92, 92, 90, 93];

const newData_Comfort = [91, 93, 92, 95, 94, 96, 95];

const xLabels_Service = ['5/11', '5/23', '6/2', '6/18', '7/7', '7/12', '7/15'];
const newData_Service = [5, 14, 6, 10, 11, 22, 49];

const xLabels_Survey = ['Q1', 'Q2', 'Q3', 'Q4'];
const newData_Survey = [3, 3, 4, 2];

const getData = (type) => {
  switch (type) {
    case 'Air Quality':
      return {
        data: newData_Air,
        xLabels: xLabels_Air,
      };
    case 'Thermal Comfort':
      return {
        data: newData_Comfort,
        xLabels: xLabels_Air,
    };
    case 'Service Time':
      return {
        data: newData_Service,
        xLabels: xLabels_Service,
      };
    case 'Survey':
      return {
        data: newData_Survey,
        xLabels: xLabels_Survey,
    };
    default:
      return {
        data: [],
        xLabels: [],
      };
  }
};


export function OccupantGraph({type}) {
  const { data, xLabels } = getData(type);

  return (
    <LineChart
      series={[
        {
          type: 'line',
          data: data,
          valueFormatter: (v) => `${v}`,
          name: 'Solid Line',
          //color: 'rgb(41, 98, 0)',
          id: 'new',
        //   label: '2024',
        },
      ]}
      xAxis={[{ data: xLabels, scaleType: 'point' }]}
      height={275}
      width={335}
      slots={{ line: CustomAnimatedLine }}
      margin={{
        left: 30,
        right: 15,
        top: 10,
        bottom: 20,
      }}
    />
  );
}
