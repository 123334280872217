// services/FeedbackService.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || "https://mybuildingalerts.com/";

// Submits feedback with optional photo
export const submitFeedback = async ({
  description,
  feedbackType,
  contactPreference,
  email,
  optionalPhoto
}) => {
  const formData = new FormData();
  formData.append("description", description);
  formData.append("feedbackType", feedbackType);
  formData.append("contactPreference", contactPreference);
  if (contactPreference === "yes" && email) {
    formData.append("email", email);
  }

  // Only append file if a user selected one
  if (optionalPhoto) {
    formData.append("photo", optionalPhoto);
  }

  const response = await axios.post(`${API_URL}/feedback/submit`, formData, {
    headers: { "Content-Type": "multipart/form-data" }
  });
  return response.data;
};
