// menuData.js
const menuData = [
    {
      id: 'home',
      title: 'Home',
      link: '/Home',
      subItems: []
    },
    {
      id: 'analysis',
      title: 'Analysis',
      link: '#',
      subItems: [
          { id: 'alerts', title: 'Alerts', link: `/ComingSoon/${encodeURIComponent('Alerts')}` },
          { id: 'asset', title: 'Asset', link: `/ComingSoon/${encodeURIComponent('Asset')}` },
          { id: 'whatif', title: 'What-if', link: `/ComingSoon/${encodeURIComponent('What-if')}` },
          { id: 'utility', title: 'Utility', link: `/ComingSoon/${encodeURIComponent('Utility')}` },
          { id: 'contractor', title: 'Contractor', link: `/ComingSoon/${encodeURIComponent('Contractor')}` },
          { id: 'service', title: 'Service', link: `/ComingSoon/${encodeURIComponent('Service')}` }
      ]
    },
    {
      id: 'submit',
      title: 'Submit',
      link: '#',
      subItems: [
        { id: 'serviceRequest', title: 'Service Request', link: '/SubmitPage?type=Request' },
        { id: 'conditionReport', title: 'Condition Report', link: '/SubmitPage?type=Report' },
        { id: 'bulletinBoardItem', title: 'Bulletin Board Item', link: `/ComingSoon/${encodeURIComponent('Bulletin Board Item')}` },
        { id: 'utilityBill', title: 'Utility Bill', link: `/ComingSoon/${encodeURIComponent('Utility Bill')}` },
        { id: 'vault', title: 'Vault', link: `/ComingSoon/${encodeURIComponent('Vault')}` }
      ]
    },
    {
      id: 'manage',
      title: 'Manage',
      link: '#',
      subItems: [
        { id: 'myProfile', title: 'My Profile', link: '/Profile' },
        { id: 'myTeam', title: 'My Team', link: `/ComingSoon/${encodeURIComponent('My Team')}` },
        { id: 'tenants', title: 'Tenants', link: '/Tenants' },
        { id: 'contractors', title: 'Contractors', link: '/Contractors' },
        { id: 'myBuildingSuite', title: 'My Building/Suite', link: '/Buildings' },
        { id: 'requests', title: 'Requests', link: `/ComingSoon/${encodeURIComponent('Requests')}`  },
        { id: 'benefitGoals', title: 'Benefit Goals', link: '/BenefitGoals' }
      ]
    },
    {
      id: 'support',
      title: 'Support',
      link: '#',
      subItems: [
        { id: 'emailSupport', title: 'Email Support', 
          link: 'https://form.jotform.com/241886657096170'},
        { id: 'appFeedback', title: 'App Feedback', 
          link: '/Feedback' },
        { id: 'faqs', title: 'FAQs', link: 'http://buildingassure.com/faq/' }
      ]
    },
    {
      id: 'about',
      title: 'About',
      link: '#',
      subItems: [
        { id: 'aboutApp', title: 'About the App', 
          link: 'http://buildingassure.com/mybuilding-alerts/' },
        { id: 'aboutUs', title: 'About Us', 
          link: 'http://buildingassure.com/about-us/' },
        { id: 'contactUs', title: 'Contact Us', 
          link: 'http://buildingassure.com/contact/' },
        { id: 'privacy', title: 'Privacy', 
          link: 'http://buildingassure.com/privacy/' }
      ]
    },
    {
      id: 'logout',
      title: 'Logout',
      link: '#',
      subItems: []
    }
  ];
  
  export default menuData;
  