import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || "https://mybuildingalerts.com/";


const getMonthlyConsumption = async (buildingId, year, month) => {
    try {
        const response = await axios.get(`${API_URL}/monthlyconsumption?buildingId=${buildingId}&year=${year}&month=${month}`, {
            withCredentials: true, 
        });
        return response.data;
    } catch (error) {
        console.error("Failed to fetch consumption", error);
        throw error;
    }
};

const getBenefitGoals = async (buildingId) => {
    try {
        const response = await axios.get(`${API_URL}/getGoals/${buildingId}`, {
            withCredentials: true, 
        });
        return response.data;
    } catch (error) {
        console.error("Failed to fetch goals", error);
        throw error;
    }
};

const setBenefitGoals = async (goalsId, newGoals) => {
    try {
        const response = await axios.put(`${API_URL}/editGoals/${goalsId}`, newGoals, {
            withCredentials: true, 
        });
        return response.data;
    } catch (error) {
        console.error("Failed to edit goals", error);
        throw error;
    }
};

const BenefitService = { 
    getMonthlyConsumption,
    getBenefitGoals,
    setBenefitGoals,
}

export default BenefitService;